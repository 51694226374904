// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-quintoandar-index-js": () => import("../src/pages/quintoandar/index.js" /* webpackChunkName: "component---src-pages-quintoandar-index-js" */),
  "component---src-pages-sobre-mim-js": () => import("../src/pages/sobre-mim.js" /* webpackChunkName: "component---src-pages-sobre-mim-js" */)
}

